<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 bg-register">
      <b-row class="navbar-auth-custom px-0 mx-0 col-sm-12">
        <b-col cols="7">
          <!-- Brand logo-->
          <a
            v-if="isEcomdyPlatform"
            href="https://ecomdymedia.com/"
            target="_blank"
          >
            <brand-logo />
          </a>
          <brand-logo v-else />
          <!-- /Brand logo-->
        </b-col>
      </b-row>
      <!-- Register Maintain -->
      <b-col
        v-if="hasMaintainMode"
        :lg="isEmediaPlatform ? 12 : 7"
        class="
          d-flex
          align-items-center
          auth-bg
          px-2
          py-lg-3
          px-xl-0
          pt-1
          register
        "
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          xl="8"
          class="max-width mx-auto form-signup"
        >
          <b-card-title
            title-tag="h1"
            class="font-medium mb-1 mt-3 d-flex"
          >
            {{ $t('register.titleRegisterBackSoon') }}
          </b-card-title>
          <b-card-text class="theme-text-secondary m-0">
            {{ $t('register.firstContentRegisterBackSoon') }}
          </b-card-text>
          <b-card-text class="theme-text-secondary">
            {{ $t('register.secondContentRegisterBackSoon') }}
          </b-card-text>
          <b-card-text
            :class="{ 'mb-2': $device.mobile }"
          >
            <span>{{ $t("resetPW.textHaveAccount") }}</span>
            <b-link
              class="text-register-now"
              :to="{ name: 'login' }"
            >
              <span>&nbsp;
                {{ $t("resetPW.btnLogin") }}
              </span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Register Maintain-->

      <!-- Register-->
      <b-col
        v-else
        :lg="isEmediaPlatform ? 12 : 7"
        class="
          d-flex
          align-items-center
          auth-bg
          px-2
          py-lg-3
          px-xl-0
          pt-1
          register
        "
      >
        <b-col
          :sm="isEmediaPlatform ? 12 : 8"
          :md="isEmediaPlatform ? 8 : 6"
          :lg="isEmediaPlatform ? 4 : 12"
          :xl="isEmediaPlatform ? 4 : 8"
          class="max-width mx-auto form-signup"
        >
          <b-card-title
            title-tag="h1"
            class="mb-1 text-center"
            style="font-size: 33px"
            :class="{'mt-3': !hasIframe}"
          >
            {{ $t("register.textWelcome") }}
          </b-card-title>
          <b-card-text
            class="theme-text-secondary text-center"
          >
            {{ $t("register.textGuide") }}
          </b-card-text>

          <!-- form step 1 -->
          <validation-observer
            ref="registerValidation"
            v-slot="{ invalid }"
          >
            <b-form class="auth-register-form mt-2">
              <!-- username -->
              <b-form-group class="my-2">
                <div class="d-flex justify-content-between">
                  <label
                    class="form-label"
                    for="username"
                  > {{ $t('common.labelFullName') }} <span class="text-danger">* </span></label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="username"
                  rules="required"
                  :name="$t('common.labelFullName')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="username"
                      id="register-username"
                      name="register-username"
                      class="input-height"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('common.placeholderFullName')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                class="my-2"
                label-for="register-email"
              >
                <div class="d-flex justify-content-between">
                  <label
                    for="email"
                    class="form-label"
                  >
                    {{ $t("common.labelEmail") }} <span class="text-danger">* </span>
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="email"
                  rules="required|email"
                  :name="$t('common.labelEmail')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="userEmail"
                      id="register-email"
                      name="register-email"
                      class="input-height"
                      :placeholder="$t('common.placeholderEmail')"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                class="my-2"
                label-for="register-password"
              >
                <div class="d-flex justify-content-between">
                  <label
                    for="password"
                    class="form-label"
                  >
                    {{ $t("common.labelPassword") }} <span class="text-danger">* </span>
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="password"
                  rules="required|password"
                  :name="$t('common.labelPassword')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="password"
                      id="register-password"
                      class="form-control-merge input-height"
                      name="register-password"
                      :placeholder="$t('common.placeholderNewPassword')"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false : null"
                      @keydown.space.prevent
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <!-- password -->
              </b-form-group>

              <b-form-group
                v-if="!!($route.query.refcode)"
                class="my-2"
              >
                <div class="d-flex justify-content-between">
                  <label class="label-Referral">
                    {{ $t('register.labelReferralCode') }} <span class="text-placeholder">(Optional)</span>
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="refCode"
                  :name="$t('register.labelReferralCode')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="refCode"
                      id="register-refCode"
                      name="register-refCode"
                      class="input-height"
                      :placeholder="$t('register.placeholderReferralCode')"
                      disabled
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="isShowPhoneNumber"
                class="group-input"
              >
                <phone-input
                  :phone-data.sync="phoneData"
                />
              </b-form-group>

              <div class="captcha">
                <div class="d-flex align-items-center">
                  <div class="spinner-captcha">
                    <label class="m-0">
                      <input
                        v-model="enabledCaptcha"
                        type="checkbox"
                        :disabled="enabledCaptcha"
                        @click="recaptcha"
                      >
                      <span class="checkmark"><span>&nbsp;</span></span>
                    </label>
                  </div>
                  <div class="text">
                    {{ $t('forgotPW.textVerifyRecaptcha') }}
                  </div>
                </div>
                <div class="logo">
                  <img src="../../assets/images/pages/auth/ic-reCAPTCHA.svg">
                  <small>{{ $t('forgotPW.textPrivacyAndTerms') }}</small>
                </div>
              </div>

              <div class="checkbox">
                <b-form-checkbox
                  v-model="selected"
                  class="custom-control-primary"
                  @click="!selected"
                >
                  {{ $t('register.textAcceptVerify') }}
                  <a
                    class="text-register-now"
                    target="_blank"
                    :href="termsOfService"
                  >{{ $t('register.textTermsOfService') }}</a>
                </b-form-checkbox>
              </div>

              <div v-if="errorMessage">
                <error-tag :error="errorMessage || ''" />
              </div>

              <btn-loading
                id="trigger-register"
                span-id="trigger-register-child"
                class=" btn-register input-height font-medium txt-color"
                variant-convert="btn-submit"
                block
                type="submit"
                :loading="loading"
                :disabled="invalid || !selected || (validPhoneInput(invalid) && isShowPhoneNumber) || !enabledCaptcha"
                @click.prevent="validationForm"
              >
                {{ $t("register.btnRegisterNow") }}
              </btn-loading>

              <!-- <div class="divider">
                <div class="divider-text text-placeholder">
                  Or register with social network
                </div>
              </div>

              <btn-loading
                class="btn-register btn-fb input-height d-flex justify-content-center align-items-center font-medium txt-color"
                tabindex="4"
                type="submit"
                variant="secondary"
                block
                :loading="loadingLoginFb"
                @click="handleLoginFacebook"
              >
                <b-img
                  class="icons-tick text-facebook"
                  fluid
                  alt="guide"
                  :src="require('@/assets/images/pages/auth/icon-fb.svg')"
                />
                Sign up with Facebook
              </btn-loading>

              <btn-loading
                class="btn-register btn-gg input-height d-flex justify-content-center align-items-center font-medium"
                tabindex="4"
                type="submit"
                variant="secondary"
                block
                :loading="loadingLoginGg"
                @click="handleLoginGoogle"
              >
                <b-img
                  class="icons-tick text-google"
                  fluid
                  alt="guide"
                  :src="require('@/assets/images/pages/auth/icon-gg.svg')"
                />
                Sign up with Google
              </btn-loading> -->
            </b-form>
          </validation-observer>
          <b-card-text
            v-if="!hasIframe"
            class="text-center"
            :class="{ 'mb-2': $device.mobile }"
          >
            <span>{{ $t("resetPW.textHaveAccount") }}</span>
            <b-link
              class="text-register-now"
              :to="{ name: 'login' }"
            >
              <span>&nbsp;
                {{ $t("resetPW.btnLogin") }}
              </span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Register-->

      <!-- Left Text-->
      <left-image v-if="!isEmediaPlatform" />
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BCardTitle,
  BCardText,
  BLink,
  // BImg,
  BInputGroupAppend,
  BFormCheckbox,
} from 'bootstrap-vue'

import { required, email, checked } from '@validations'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'
import { $themeConfig } from '@themeConfig'
import { mapGetters } from 'vuex'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import LeftImage from '@/layouts/components/LeftImage.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { SOCIAL_OPTIONS, TERMS_LINK } from '@/constants'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { AuthService } from '@/services'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import errorTag from '@/components/errorTag.vue'
import phoneInput from './PhoneInput.vue'

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_CAPTCHA_KEY,
})

export default {
  components: {
    // BIconGoogle,
    BRow,
    BCol,
    // BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BLink,
    BInputGroupAppend,
    BFormCheckbox,
    // BAlert,
    // BImg,
    // BInputGroupAppend,
    // BInputGroupPrepend,
    // BImg,
    // BButton,
    // BProgressBar,
    // BProgress,
    // BFormCheckbox,

    // components
    BtnLoading,
    BrandLogo,
    LeftImage,

    // validations
    ValidationProvider,
    ValidationObserver,
    phoneInput,

    errorTag,
  },
  mixins: [toastification, togglePasswordVisibility, envMixin],

  data() {
    return {
      terms: false,
      termsLink: TERMS_LINK,
      username: '',
      userEmail: '',
      password: '',
      passwordConfirm: '',
      refCode: this.$route.query.refcode || '',
      imgUrl: require('@/assets/images/pages/auth/register.png'),
      benefits: [
        { title: this.$t('register.textGetGlobal') },
        { title: this.$t('register.textFreeDayService') },
        { title: this.$t('register.textUnlimitedSpending') },
        { title: this.$t('register.textGlobalDeposit') },
        { title: this.$t('register.textEarnMoney') },
        { title: this.$t('register.textJoinWithCommunity') },
        { title: this.$t('register.textLiveChat') },
        { title: this.$t('register.textSupport') },
      ],
      isRegisterSuccess: false,

      // Toggle Password
      password1FieldType: 'password',
      // password2FieldType: 'password',

      // Step signup
      values: [50, 0],
      max: 100,
      currentStep: 1,
      phoneData: {
        countryAbbr: 'vn',
        countryCode: '',
        phone: '',
        isValid: true,
        isRequired: false,
      },

      // social
      socialSelected: SOCIAL_OPTIONS[0],
      socialContact: '',

      // validation
      required,
      email,
      checked,

      loadingLoginFb: false,
      loadingLoginGg: false,
      selected: false,
      isShowPhoneNumber: false,

      enabledCaptcha: false,
      tokenCaptcha: '',
      errorMessage: '',
    }
  },

  setup() {
    // App Name
    const { appName } = $themeConfig.app
    return {
      appName,
    }
  },

  computed: {
    ...mapGetters({
      status: 'auth/status',
      error: 'auth/error',
      loading: 'auth/loading',
      message: 'auth/message',
    }),

    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    // password2ToggleIcon() {
    //   return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    // },

    validPhoneInput() {
      return invalid => invalid || this.phoneData.phone === '' || (this.phoneData.phone !== '' && !this.phoneData.isValid)
    },

    hasIframe() {
      return window !== window.top
    },

    isRefCodeWajahat() {
      return this.refCode === process.env.VUE_APP_REFCODE_WAJAHAT
    },
  },

  created() {
    // if (process.env.VUE_APP_MAINTAIN_MODE === 'true') {
    //   this.$router.push('/holiday')
    // }
  },

  mounted() {
    if (this.refCode) {
      const params = {
        refCode: this.refCode,
      }
      this.$store.dispatch('referral/track', { params })
    }

    if (this.isEcomdyPlatform && this.refCode === process.env.VUE_APP_REFCODE_WAJAHAT) {
      this.isShowPhoneNumber = true
    }
  },

  methods: {
    resetRecaptcha() {
      this.tokenCaptcha = ''
      this.enabledCaptcha = false
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      this.tokenCaptcha = await this.$recaptcha('login')

      // Do stuff with the received token.
    },

    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },

    // togglePassword2Visibility() {
    //   this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    // },

    async validationForm() {
      this.errorMessage = ''
      const success = await this.$refs.registerValidation.validate()
      if (success || this.enabledCaptcha) {
        const params = {
          tokenCaptcha: this.tokenCaptcha,
          fullName: this.username,
          email: this.userEmail,
          password: this.password,
          refCode: this.refCode,
          ...(this.isShowPhoneNumber && {
            contactInfo: {
              phone: `${this.phoneData.countryAbbr}|${this.phoneData.countryCode}|${this.phoneData.phone}`,
            },
          }),
        }
        await this.$store.dispatch('auth/register', { params })

        if (this.status) {
          if (this.isVerifyEmailLater) {
            await this.$router.push('/login')
            this.toastSuccess(this.$t('register.noticeRegisterSuccess'))
          } else {
            await this.$router.push(`/verify-email?email=${encodeURIComponent(this.userEmail)}`)
            if (this.isEcomdyPlatform) {
              localStorage.setItem('show-kickstart-modal', 'show')
            }
          }
        } else {
          this.resetRecaptcha()
          if (this.error.data.code === 'error.register_by_disposable_email') {
            this.errorMessage = this.message
          } else {
            this.toastFailure(this.message)
          }
        }
      }
    },

    handleLoginFacebook() {
      this.loadingLoginFb = true
      try {
        AuthService.loginFacebook()
        this.loadingLoginFb = false
      } catch {
        this.loadingLoginFb = false
        this.toastFailure(this.$t('register.noticeRegisterFail'))
      }
    },

    handleLoginGoogle() {
      this.loadingLoginGg = true
      try {
        AuthService.loginGoogle()
        this.loadingLoginGg = false
      } catch {
        this.loadingLoginGg = false
        this.toastFailure(this.$t('register.noticeRegisterFail'))
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
<style lang="scss" scoped>
.auth-wrapper .auth-bg {
  background-color: #f8f8fa;
}
  .warning-message {
    background: rgba(251, 176, 59, 0.1);
    border-radius: 10px;
    padding: 10px 12px 10px 16px;
  }

.auth-inner {
  height: 100vh;
}

.theme-text-secondary {
  margin-bottom: 35px;
}

.form-signup {
  padding: 0 3rem;
}
.sign-up-text {
  padding: 0 80px;
  .description {
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
  }
}
.text-description {
  list-style-type: none;
  .icons-tick {
    position: relative;
    top: 5px;
  }
  .title {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    list-style-type: none;
    text-align: left;
    margin-bottom: 1.375rem;
    margin-left: 1rem;
  }
}
.label-Referral {
  padding-bottom: 4px;
}
.input-height {
  height: 50px;
}

.txt-color {
  color: #fcf7f8;
}

.btn-submit {
  border-radius: 100px !important;
}

.btn-register {
  margin: 2rem 0;
}

.form-contact {
  margin-top: 1rem;
}

@media (max-width: 767px) {
  .form-signup {
    top: 3rem;
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-auth-custom {
    width: calc(100%);
  }
  .btn-submit {
    margin-bottom: 2rem;
  }
}

@media (max-width: 991px) {
  .form-password,
  .form-numberPhone {
    margin-top: 1.5rem;
  }
}

.btn-block {
  border: 1px solid #E0E0E5 !important;
  border-radius: 100px !important;
}

.btn-fb {
    background-color: #fff !important;
    color: #000 !important;
    margin-bottom: 12px;
    &:hover {
      box-shadow: 0 8px 25px -8px rgba(0, 0, 0, 0.3) !important;
    }
  }

  .btn-gg {
    background-color: #fff !important;
    color: #000 !important;
    margin-top: 12px;
    &:hover {
      box-shadow: 0 8px 25px -8px rgba(0, 0, 0, 0.3) !important;
    }
  }

  .icons-tick {
    margin-right: 12px;
  }

.captcha {
  background-color: #f9f9f9;
  border: 1px solid #D6D6D6;
  border-radius: 2px;
  color: #4c4a4b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  height: 74px;
  padding: 8px 10px 7px 12px;
  margin-bottom: 1rem;

  .text {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 14px;

  }

  .spinner-captcha {
    position: relative;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  input[type="checkbox"] + .checkmark {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: #fcfcfc;
    border: 2.5px solid #c3c3c3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  input[type="checkbox"] + .checkmark span {
    content: "";
    position: relative;
    margin-top: -3px;
    transform: rotate(45deg);
    width: 0.75em;
    height: 1.2em;
    opacity: 0;
  }

  input[type="checkbox"] + .checkmark > span:after {
    content: "";
    position: absolute;
    display: block;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #029f56;
  }

  input[type="checkbox"] + .checkmark > span:before {
    content: "";
    position: absolute;
    display: block;
    width: 3px;
    bottom: 0;
    right: 0;
    background-color: #029f56;
  }

  input[type="checkbox"]:checked + .checkmark {
    animation: 2s spin forwards;
  }

  input[type="checkbox"]:checked + .checkmark > span {
    animation: 1s fadein 1.9s forwards;
  }

  input[type="checkbox"]:checked + .checkmark > span:after {
    animation: 0.3s bottomslide 2s forwards;
  }

  input[type="checkbox"]:checked + .checkmark > span:before {
    animation: 0.5s rightslide 2.2s forwards;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes bottomslide {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes rightslide {
    0% {
      height: 0;
    }
    100% {
      height: 100%;
    }
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    align-self: flex-end;
  }

  .logo small {
    text-align: center;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #9d9ba7;
    margin: 0;
    font-size: 8px;
  }

  @keyframes spin {
    10% {
      width: 0;
      height: 0;
      border-width: 6px;
    }
    30% {
      width: 0;
      height: 0;
      border-radius: 50%;
      border-width: 1em;
      transform: rotate(0deg);
      border-color: rgb(199, 218, 245);
    }
    50% {
      width: 2em;
      height: 2em;
      border-radius: 50%;
      border-width: 4px;
      border-color: rgb(199, 218, 245);
      border-right-color: rgb(89, 152, 239);
    }
    70% {
      border-width: 4px;
      border-color: rgb(199, 218, 245);
      border-right-color: rgb(89, 152, 239);
    }
    90% {
      border-width: 4px;
    }
    100% {
      width: 2em;
      height: 2em;
      border-radius: 50%;
      transform: rotate(720deg);
      border-color: transparent;
    }
  }
  ::selection {
    background-color: transparent;
    color: teal;
  }
  ::-moz-selection {
    background-color: transparent;
    color: teal;
  }

  @media screen and (max-width: 500px) {
    .captcha {
      flex-direction: column;
    }
    .text {
      margin: 0.5em !important;
      text-align: center;
    }
    .logo {
      align-self: center !important;
    }
    .spinner-captcha {
      margin: 2em 0.5em 0.5em 0.5em !important;
    }
  }
}

.bg-register {
  background-color: #f8f8fa;
}
</style>
