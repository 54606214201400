<template>
  <div>
    <!--     v-if="hasMaintainMode"-->
    <div v-if="false">
      <div class="mb-2 warning-message">
        <div>
          <h5 class="mb-2 title">
            {{ $t('home.maintenance.titleMaintenance') }}
          </h5>
          <p>{{ $t('home.maintenance.textHiThere') }} <span style="width: 30px">🙌</span></p>
          <p>{{ $t('home.maintenance.firstContent') }}</p>
          <p>{{ $t('home.maintenance.secondContent') }}</p>
          <p>{{ $t('home.maintenance.finalContent') }}</p>
        </div>
      </div>
    </div>

    <div v-else-if="isExclusivePartner">
      <warning-notification
        class="notification-refund-policy"
        :link-detail="linkDetail"
      >
        <template #title>
          <div class="title-notification">
            <div class="ic-docs">
              <feather-icon
                icon="FileTextIcon"
                size="24"
              />
            </div>
            <h3 class="m-0">
              [AdReach] Refund Policy
            </h3>
            <div class="ic-notification">
              <feather-icon
                icon="ArrowUpRightIcon"
                size="21"
              />
            </div>
          </div>
        </template>

        <template #content>
          <div>
            <p class="m-0">
              Please review our refund policy carefully to understand your rights and obligations when requesting a refund.
            </p>
          </div>
        </template>
      </warning-notification>
    </div>

    <warning-notification v-if="isSMBAgencyPlatform">
      <template #title>
        <div class="d-flex">
          <img
            class="alert-img mr-50 mb-1"
            alt="alert"
            width="26"
            :src="require('@/assets/images/common/ic-speaker.png')"
          >
          <p class="font-weight-bold">
            {{ $t('home.warningNotificationCashbackSBH.titleNotification', {platform: `${ platformName }`}) }}
          </p>
        </div>
      </template>
      <template #content>
        <p class="mb-0">
          {{ $t('home.warningNotificationCashbackSBH.content1') }}
        </p>
        <p class="mb-0">
          {{ $t('home.warningNotificationCashbackSBH.content2') }}
        </p>
        <p class="mb-0">
          {{ $t('home.warningNotificationCashbackSBH.content3') }}
        </p>
        <p>
          {{ $t('home.warningNotificationCashbackSBH.content4') }}
        </p>
        <div class="d-flex align-items-center">
          <span>{{ $t('home.warningNotificationCashbackSBH.thankyou') }}
            <user-hint
              :hide-icon="true"
              :hide-quote="true"
            />
          </span>
        </div>
      </template>
    </warning-notification>

    <banner-header
      v-if="isEcomdyPlatform && (generalConfigs.system && !isExclusivePartner)"
      :img="require('@/assets/images/banner/banner-desktop.jpeg')"
      :img-laptop="require('@/assets/images/banner/banner-laptop.png')"
      :img-tablet="require('@/assets/images/banner/banner-tablet.png')"
      :img-mobile="require('@/assets/images/banner/banner-mobile.png')"
      link="https://ecomdycom.larksuite.com/file/EMw0beTOGogcPWxS4QnuKf30seh"
    />

    <banner-header
      v-else-if="isFromTikTok"
      :img="require('@/assets/images/banner/banner-tiktok-app-center.png')"
    />

    <banner-affiliate-program
      v-else-if="isEcomdyPlatform && !isPartner && !isExclusivePartner && !userInfoLoading"
      class="mb-2"
    />

    <div
      v-if="showAdsBlockNotice"
    >
      <ads-blocked-notice />
    </div>

    <div v-else-if="!userInfoLoading">

      <summary-balance
        :has-referral-tracking="hasReferralTracking"
        :spent-today="spentToday"
        :total-spending="totalSpending"
        :user-report-loading="userReportLoading"
        :user-info-loading="userInfoLoading"
        :received-ref-money="receivedRefMoney"
        :count-used="countUsed"
        :total-account="totalAccount"
        :partner-bc-id="partnerBC"
        class="d-none d-md-flex"
      />

      <div class="d-flex align-items-start">
        <list-ads-account class="width-table" />
        <list-event-slide
          v-if="false"
          class="width-slide mt-2 mt-md-0"
        />
      </div>
    </div>

    <gift-code-notification-modal
      ref="open-modal-gift-code-notification"
      @hide-modal-confirm="hideModalGiftCode"
    />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import adsAccount from '@/mixins/adsAccount'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import envMixin from '@/mixins/envMixin'
// import SetupGuide from '@/components/setup-guide-component/SetupGuide.vue'
import BannerHeader from '@/views/home/components/BannerHeader.vue'
import partnerMixin from '@/mixins/partnerMixin'
import UserHint from '@/components/UserHint.vue'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import SummaryBalance from './components/Summary.vue'
import ListAdsAccount from './components/ListAdsAccount.vue'
import ListEventSlide from './components/ListEventSlide.vue'
import AdsBlockedNotice from './components/AdsBlockedNotice.vue'
import GiftCodeNotificationModal from './components/GiftCodeNotificationModal.vue'
import BannerAffiliateProgram from './components/BannerAffiliateProgram.vue'
import WarningNotification from './components/WarningNotification.vue'

const { mapGetters } = createNamespacedHelpers('adsAccount')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')
const { mapActions: mapActionsGiftCode, mapGetters: mapGettersGiftCode } = createNamespacedHelpers('giftCode')
export default {
  components: {
    UserHint,
    ListAdsAccount,
    SummaryBalance,
    ListEventSlide,

    AdsBlockedNotice,
    GiftCodeNotificationModal,
    BannerHeader,
    // SetupGuide,
    BannerAffiliateProgram,
    WarningNotification,
  },

  mixins: [numberFormatMixin, adsAccount, stepGuideMixin, envMixin, partnerMixin, generalConfigsMixin],

  data() {
    return {
      isHideModalGiftCode: false,
      linkDetail: 'https://ecomdycom.larksuite.com/docx/NA9LdCbBNo2IQFxXCkluRequsGe',
    }
  },

  computed: {
    ...mapGetters([
      'listAccount',
      'message',
      'status',
      'loading',
    ]),
    ...mapGettersAuth(['user', 'userReports', 'generalConfigs']),
    ...mapGettersGiftCode(['listGiftCodePartner']),
    spentToday() {
      return this.amountFormat(
        _get(this.userReports, ['data', 'spentToday'], 0),
      )
    },

    totalSpending() {
      return this.amountFormat(
        _get(this.userReports, ['data', 'totalSpent'], 0),
      )
    },

    userReportLoading() {
      return _get(this.userReports, ['loading'], false)
    },

    totalUserBalance() {
      return _get(this.user, ['data', 'balance'], 0)
    },

    userInfoLoading() {
      return _get(this.user, ['loading'], false)
    },

    hasReferralTracking() {
      return _get(this.user, ['data', 'referralTracking'], {})
    },

    receivedRefMoney() {
      return _get(this.user, ['data', 'referralTracking', 'receivedMoney'], 0)
    },

    countUsed() {
      return _get(this.user, ['data', 'referralTracking', 'countUsed'], 0)
    },

    totalAccount() {
      return _get(this.listAccount, ['paging', 'total'], 0)
    },

    partnerBC() {
      return _get(this.listAccount, ['content', 0, 'partnerBCId'], '')
    },

    idPartner() {
      return _get(
        this.user,
        ['data', 'belongToPartner', 'partnerId', '_id'],
        '',
      )
    },

    isBanedBC() {
      return _get(this.user, ['data', 'alert', 'hasBanedBC'], false)
    },

    showAdsBlockNotice() {
      return false
      /* --- Open if needed --*/
      //  return this.isBanedBC && this.isEcomdyPlatform
    },
  },

  watch: {
    user: {
      handler(userInfo) {
        if (userInfo?.data?.belongToPartner) {
          this.fetchListGiftCodePartner()
        }
      },
      deep: true,
      immediate: true,
    },

    listGiftCodePartner: {
      handler(giftCode) {
        if (giftCode?.paging?.total > 0) {
          const giftCodeActive = giftCode?.content?.find(item => item?.status === 'active' && (item.quantity > item.savedQuantity))
          this.$nextTick(() => {
            if (giftCodeActive && !this.isHideModalGiftCode && !this.userInfoLoading) {
              this.openNotificationGiftCodeModal()
            }
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.isHideModalGiftCode = JSON.parse(localStorage.getItem('isHideModalGiftCode'))
  },

  methods: {
    ...mapActionsGiftCode(['getListGiftCodePartner']),

    openNotificationGiftCodeModal() {
      this.$refs['open-modal-gift-code-notification'].showModal()
    },

    hideModalGiftCode() {
      this.isHideModalGiftCode = true
      localStorage.setItem('isHideModalGiftCode', JSON.stringify(this.isHideModalGiftCode))
    },

    async fetchListGiftCodePartner() {
      const params = {
        id: this.user?.data?.belongToPartner?.partnerId?._id,
        page: 1,
        size: 10,
      }

      await this.getListGiftCodePartner(params)
    },
  },
}
</script>

<style lang="scss" scoped>
  .warning-message {
    background: rgba(251, 176, 59, 0.1);
    border-radius: 10px;
    padding: 22px;
  }

  .width-table {
    //width: calc(100% - 344px);
    width: 100%;

    @media (max-width: 767px) {
    margin-right: 0;
      width: 100%;
    }

    @media (min-width: 768px) and (max-width: 992px) {
      //width: calc(100% - 294px);
      width: 100%;
    }
  }

  .width-slide {
    width: max-content;
    height: max-content;
    margin-left: 24px;

    @media (max-width: 767px) {
      width: 100%;
    }

    @media (min-width: 768px) and (max-width: 992px) {
      width: 270px;
    }
  }

  .notification-refund-policy {
    cursor: pointer;
    background: rgba(38, 103, 255, 0.1) !important;

    .title-notification {
      margin-bottom: .5rem;

      display: flex;
      align-items: center;

      .ic-docs {
        margin-right: 5px;
      }

      .ic-notification {
        margin-left: 6px;
        color: #2667ff;
      }
    }
  }
</style>
