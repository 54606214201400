<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 bg-forgot-password">
      <b-row class="navbar-auth-custom px-0 mx-0 col-sm-12">
        <b-col cols="7">
          <!-- Brand logo-->
          <brand-logo />
          <!-- /Brand logo-->
        </b-col>
      </b-row>
      <!-- Forgot password-->
      <b-col
        :lg="isEmediaPlatform ? 12 : 7"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          :sm="isEmediaPlatform ? 12 : 8"
          :md="isEmediaPlatform ? 8 : 6"
          :lg="isEmediaPlatform ? 8 : 11"
          :xl="isEmediaPlatform ? 4 : 7"
          class="form-forgot max-width mx-auto"
        >
          <b-card-title
            title-tag="h1"
            class="font-medium mb-2 d-flex justify-content-center"
          >
            {{ $t("forgotPW.textWelcome") }}
          </b-card-title>
          <b-card-text class="mb-3 text-center theme-text-secondary">
            {{ $t("forgotPW.textGuide") }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="simpleRules"
            v-slot="{ invalid }"
          >
            <b-form
              class="auth-forgot-password-form mt-2"
            >
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label class="form-label">
                    {{ $t("common.labelEmail") }}
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="userEmail"
                      id="forgot-password-email"
                      name="forgot-password-email"
                      class="input-height form-input"
                      :placeholder="$t('forgotPW.placeholderEmail')"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger input-group">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="captcha">
                <div class="d-flex align-items-center">
                  <div class="spinner-captcha">
                    <label class="m-0">
                      <input
                        v-model="enabledCaptcha"
                        type="checkbox"
                        :disabled="enabledCaptcha"
                        @click="recaptcha"
                      >
                      <span class="checkmark"><span>&nbsp;</span></span>
                    </label>
                  </div>
                  <div class="text">
                    {{ $t('forgotPW.textVerifyRecaptcha') }}
                  </div>
                </div>
                <div class="logo">
                  <img src="../../assets/images/pages/auth/ic-reCAPTCHA.svg">
                  <small>{{ $t('forgotPW.textPrivacyAndTerms') }}</small>
                </div>
              </div>

              <btn-loading
                class="my-3 input-height font-medium txt-color"
                variant-convert="btn-submit"
                block
                :disabled="invalid || !enabledCaptcha"
                :loading="loading"
                @click="validationForm"
              >
                {{ $t("forgotPW.btnSendLinkReset") }}
              </btn-loading>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2 theme-text-secondary">
            <span>{{ $t("forgotPW.textNoAccount") }}</span>
            <b-link
              class="text-register-now"
              :to="{ name: 'register' }"
            >
              <span>&nbsp;
                {{ $t("forgotPW.btnRegisterNow") }}
              </span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Forgot password-->

      <!-- Left Text-->
      <left-image v-if="!isEmediaPlatform" />
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import { mapGetters, mapActions } from 'vuex'
import { toastification } from '@core/mixins/toast'
import LeftImage from '@/layouts/components/LeftImage.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import envMixin from '@/mixins/envMixin'

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_CAPTCHA_KEY,
})

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BCardTitle,
    BCardText,

    // components
    BtnLoading,
    BrandLogo,
    LeftImage,

    // validate
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toastification, envMixin],
  data() {
    return {
      userEmail: '',
      imgUrl: require('@/assets/images/pages/auth/forgot-pass.png'),
      // validation
      required,
      email,
      enabledCaptcha: false,
      tokenCaptcha: '',
    }
  },
  computed: {
    ...mapGetters({
      status: 'auth/status',
      error: 'auth/error',
      loading: 'auth/loading',
      message: 'auth/message',
    }),
  },
  methods: {
    ...mapActions({
      resetInit: 'auth/resetInit',
    }),

    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      this.tokenCaptcha = await this.$recaptcha('login')

      // Do stuff with the received token.
    },

    async validationForm() {
      const success = await this.$refs.simpleRules.validate()
      if (success || this.enabledCaptcha) {
        const params = {
          email: this.userEmail,
          tokenCaptcha: this.tokenCaptcha,
        }
        await this.resetInit(params)
        if (this.status) {
          this.toastSuccess(this.$t('forgotPW.textForgotPWSuccess'))
          this.$router.push({ name: 'login' })
        } else {
          this.toastFailure(this.message)
          this.resetForm()
        }
      }
    },

    resetForm() {
      this.enabledCaptcha = false
      this.$refs.simpleRules.reset()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
<style lang="scss" scoped>
.auth-wrapper .auth-bg {
  background-color: #f8f8fa;
}

.form-forgot {
  padding: 0 2.2rem;
}
.input-height {
  height: 54px;
}

.txt-color {
  color: #fcf7f8;
}
.btn-submit {
  border-radius: 100px;
}

@media (max-width: 767px) {
  .form-forgot {
    padding-left: 0;
    padding-right: 0;
    .description {
      line-height: 24px;
      margin-bottom: 2rem !important;
    }
  }
  .navbar-auth-custom {
    width: calc(100%);
  }
}

.captcha {
  background-color: #f9f9f9;
  border: 1px solid #D6D6D6;
  border-radius: 2px;
  color: #4c4a4b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  height: 74px;
  padding: 8px 10px 7px 12px;

  .text {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 14px;
  }

  .spinner-captcha {
    position: relative;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  input[type="checkbox"] + .checkmark {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: #fcfcfc;
    border: 2.5px solid #c3c3c3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  input[type="checkbox"] + .checkmark span {
    content: "";
    position: relative;
    margin-top: -3px;
    transform: rotate(45deg);
    width: 0.75em;
    height: 1.2em;
    opacity: 0;
  }

  input[type="checkbox"] + .checkmark > span:after {
    content: "";
    position: absolute;
    display: block;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #029f56;
  }

  input[type="checkbox"] + .checkmark > span:before {
    content: "";
    position: absolute;
    display: block;
    width: 3px;
    bottom: 0;
    right: 0;
    background-color: #029f56;
  }

  input[type="checkbox"]:checked + .checkmark {
    animation: 2s spin forwards;
  }

  input[type="checkbox"]:checked + .checkmark > span {
    animation: 1s fadein 1.9s forwards;
  }

  input[type="checkbox"]:checked + .checkmark > span:after {
    animation: 0.3s bottomslide 2s forwards;
  }

  input[type="checkbox"]:checked + .checkmark > span:before {
    animation: 0.5s rightslide 2.2s forwards;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes bottomslide {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes rightslide {
    0% {
      height: 0;
    }
    100% {
      height: 100%;
    }
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    align-self: flex-end;
  }

  .logo small {
    text-align: center;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #9d9ba7;
    margin: 0;
    font-size: 8px;
  }

  @keyframes spin {
    10% {
      width: 0;
      height: 0;
      border-width: 6px;
    }
    30% {
      width: 0;
      height: 0;
      border-radius: 50%;
      border-width: 1em;
      transform: rotate(0deg);
      border-color: rgb(199, 218, 245);
    }
    50% {
      width: 2em;
      height: 2em;
      border-radius: 50%;
      border-width: 4px;
      border-color: rgb(199, 218, 245);
      border-right-color: rgb(89, 152, 239);
    }
    70% {
      border-width: 4px;
      border-color: rgb(199, 218, 245);
      border-right-color: rgb(89, 152, 239);
    }
    90% {
      border-width: 4px;
    }
    100% {
      width: 2em;
      height: 2em;
      border-radius: 50%;
      transform: rotate(720deg);
      border-color: transparent;
    }
  }
  ::selection {
    background-color: transparent;
    color: teal;
  }
  ::-moz-selection {
    background-color: transparent;
    color: teal;
  }

  @media screen and (max-width: 500px) {
    .captcha {
      flex-direction: column;
    }
    .text {
      margin: 0.5em !important;
      text-align: center;
    }
    .logo {
      align-self: center !important;
    }
    .spinner-captcha {
      margin: 2em 0.5em 0.5em 0.5em !important;
    }
  }
}

.bg-forgot-password {
  background-color: #f8f8fa;
}
</style>
